import {manifest, version} from '@parcel/service-worker'

declare var self: ServiceWorkerGlobalScope

async function doInstall() {
	const cache = await caches.open(version)
	await cache.addAll([
		...manifest,
	])
}
self.addEventListener('install', ev => {
	self.skipWaiting()
	ev.waitUntil(doInstall())
})

async function doActivate() {
	await Promise.all(
		(await caches.keys()).filter(key => key !== version).map(key => caches.delete(key))
	)
}
self.addEventListener('activate', ev => ev.waitUntil(doActivate()))

async function doFetch(request: Request) {
	const cache = await caches.open(version)

	if (request.mode == 'navigate') {
		return (await cache.match(new Request('/main.html')))!
	}

	const match = await cache.match(request)
	if (match)
		return match

	return fetch(request)
}
self.addEventListener('fetch', ev => ev.respondWith(doFetch(ev.request)))
